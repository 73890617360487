.topBar {
  background-color: #e0e0e0;
  height: 50px;
  margin-bottom: 120px;
}

.imgVila {
  height: 24px;
  margin-top: 14px;
  margin-left: 40px;
  cursor: pointer;
}

.imgBestseller {
  height: 13px;
  margin-top: 19px;
  margin-left: 40px;
  cursor: pointer;
}

.environmentBox {
  margin: 8px 20px 0px 0px;
  padding: 8px 12px;
  font-size: 14px;
  color: #fff;
  background-color: #d9534f;
  border: 1px solid transparent;
  border-radius: 3px;
  float: right;
}
