.brandsTable {
  width: 100%;
  margin-top: 80px;
}

.brandProductlineHeader {
  font-weight: bold;
  width: 50%;
  padding-bottom: 10px;
}

.salesPersonHeader {
  font-weight: bold;
  text-align: right;
  width: 50%;
  padding-bottom: 10px;
}

.tableBottomBorder {
  width: 100%;
  border-top-style: solid;
  border-top-width: 2px;
  border-top-color: black;
}
