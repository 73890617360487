.ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.launchIcon {
  width: 15px;
  margin-top: 5px;
}

/* Start Style*/
.main__menu {
  font-size: 14px;
  position: absolute;
  top: 7px;
  right: 10px;
  .listItem {
    &:hover {
      .dropMenu li {
        display: block;
      }
      .menu2 {
        .dropItem {
          transform-origin: top center;
          @for $i from 1 through 4 {
            &:nth-child(#{$i}) {
              animation-name: topToBottom;
              animation-duration: 400ms;
              animation-delay: (150ms * $i) - 300;
              animation-timing-function: ease-in-out;
              animation-fill-mode: forwards;
            }
          }
        }
      }
    }
    .a {
      padding-left: 15px;
      padding-right: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 36px;
      border-radius: 3px;
      color: #fff;
      cursor: default;
      span {
        order: 2;
      }
      &.topMenu {
        background-color: #d9534f;
      }
    }
    .dropMenu {
      .dropItem {
        display: none;
        .a {
          height: 36px;
          &.item1 {
            background: #a5a7a8;
            cursor: pointer;
          }
          &.item2 {
            background: #797979;
            cursor: pointer;
          }
          &.item3 {
            background: #606262;
            cursor: pointer;
          }
        }
      }
    }
  }
}

@keyframes topToBottom {
  0% {
    opacity: 0;
    transform: rotateX(-90deg);
  }
  100% {
    opacity: 1;
    transform: rotateX(0deg);
  }
}
