$margin: 8px;

.flexContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  margin-right: 0-$margin;
}

.postalCode {
  flex-grow: 1;
  width: 45%;
  margin-right: $margin;
}

.callingCode {
  flex-grow: 1;
  width: 85px;
  margin-right: $margin;
}

.phoneNumber {
  flex-grow: 10;
  justify-content: space-between;
  margin-right: $margin;
}

.city {
  flex-grow: 8;
  justify-content: space-between;
  margin-right: $margin;
}
